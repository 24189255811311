.car__item {
  border: 1px solid #2a2c39;
  padding: 22px;
  border-radius: 5px;
}

.car__item-info span i {
  color: #f9a826;
  font-size: 1.1rem;
}

.car__item-btn {
  border-radius: 0;
  border: none;
  outline: none;
  background: #37393e;
  /* background: #000d6b; */
  padding: 6px 0px;
}

.car__item-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.car__item-btn a:hover {
  color: #fff;
}
.car__btn-details {
  background: #f9a826;
  border-radius: 0px 2px 2px 0px;
}

.car__btn-rent {
  border-radius: 2px 0px 0px 2px;
  margin: 0px 3px 0px 3px;
}

.car__item-content h4 {
  font-size: 1.8rem;
}

.car__item-content h6 {
  font-size: 1.2rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .car__item h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .car__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .car__item-info {
    flex-wrap: wrap;
  }

  .car__item-info span {
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .car__item-btn {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .car__item h4 {
    font-size: 1rem;
  }

  .car__item h6 {
    font-size: 0.9rem;
  }

  .car__item-info span {
    font-size: 0.7rem;
  }

  .car__item-info span i {
    font-size: 0.9rem;
  }

  .car__item-btn {
    font-size: 0.8rem;
  }
}

.car__item.fade-effect {
  position: relative;
  overflow: hidden; /* Ensure pseudo-elements do not affect layout */
  border-radius: 10px; /* Optional: Add border-radius for better visual effect */
}

.car__item.fade-effect::before,
.car__item.fade-effect::after {
  content: "";
  position: absolute;
  pointer-events: none;
}

.car__item.fade-effect::before {
  top: 0;
  left: 0;
  right: 0;
  height: 10px; /* Adjust height for the fade effect */
  background: linear-gradient(to bottom, rgba(60, 60, 110, 0.5), transparent);
}

.carousel-image-container {
  width: 100%; /* Or set a specific width */
  max-width: 600px; /* Optional: Set a max width */
  height: 350px; /* Set a fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* Ensures white borders */
  overflow: hidden; /* Ensures no excess */
}

.carousel-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;  /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures full image is visible */
}

/* 📱 Mobile: Adjust for smaller screens */
@media (max-width: 768px) {
  .carousel-image-container {
    max-width: 100%; /* Make it more flexible for small screens */
    height: 200px; /* Reduce height on mobile */
  }
}

@media (min-width: 1200px) {
  .carousel-image-container {
    max-width: 700px; /* Increase width for larger screens */
    max-height: 300px; /* Increase height accordingly */
  }
}

