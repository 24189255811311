.whatsapp_float {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    border-radius: 50%;
    width: 60px; /* Adjust as needed */
    height: 60px; /* Adjust as needed */
    transition: background-color 0.3s, box-shadow 0.3s;
    z-index: 1000;
}

.whatsapp_float:hover {
    background-color: #f0f0f0;
}

.whatsapp-icon {
    color: #222831;
    font-size: 36px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .whatsapp_float {
        bottom: 10px;  /* Adjust as needed for mobile */
        right: 10px;   /* Adjust as needed for mobile */
        width: 50px;   /* Adjust as needed for mobile */
        height: 50px;  /* Adjust as needed for mobile */
    }
    
    .whatsapp-icon {
        font-size: 28px; /* Adjust icon size for smaller screens */
    }
}
