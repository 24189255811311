.carousel-image-container-details {
    width: 100%; /* Or set a specific width */
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white; /* Ensures white borders */
    overflow: hidden; /* Ensures no excess */
  }
  
  .carousel-image-details {
    max-width: 100%;
    max-height: 100%;
    width: auto;  /* Maintain aspect ratio */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensures full image is visible */
  }

  .carousel.carousel-slider .control-arrow
  {
    width: 50px;
  }
  

  @media (max-width: 768px) {
    .carousel-image-container-details {
      max-width: 100%; /* Make it more flexible for small screens */
      height: 400px; /* Reduce height on mobile */
    }
    .carousel.carousel-slider .control-arrow
    {
        width: 35px;
    }
    }

  @media (min-width: 1200px) {
    .carousel-image-container-details {
      max-width: 1200px; /* Increase width for larger screens */
      height: 900px; /* Increase height accordingly */
    }
  }

  